.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  outline: none;
  padding: 1rem;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: 1;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modalMobile {
  position: fixed;
  width: 70%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  outline: none;
  padding: 1rem;
  max-width: 20rem;
  width: 100%;
  overflow-y: scroll;
}
